<template>
  <q-select
    filled
    v-model="title"
    use-input
    hide-selected
    fill-input
    input-debounce="0"
    :options="options"
    @filter="filterFn"
    @input-value="setModel"
    :placeholder="placeholder"
    lazy-rules
    :rules="[val => (val && val.length > 0) || 'Obligatoire']"
  >
  </q-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AutocompleteEntity',
  props: {
    editLabelTitle: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: [],
      originOptions: [],
      title: this.editLabelTitle
    }
  },
  async created() {
    const body = document.querySelector('body')
    body.classList.add('placeholders')

    await this.fetchPlaceholders()

    let result = []
    this.getPlaceholders.forEach(placeholder => {
      placeholder.categoryLabels.forEach(label => {
        if (result.length) {
          // if exists
          const index = result.findIndex(item => item.label === label.dataLabel)
          if (index > -1) return
        }

        result.push({
          label: label.dataLabel,
          value: label.dataLabel
        })
      })
    })
    result = result.sort((a, b) => a.label.localeCompare(b.label))
    this.options = this.originOptions = result
  },
  computed: {
    ...mapGetters('projects', ['getPlaceholders'])
  },
  methods: {
    ...mapActions('projects', ['fetchPlaceholders']),
    filterFn(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.options = this.originOptions
          .filter(v => v.label.toLocaleLowerCase().indexOf(needle) > -1)
          .sort((a, b) => a.label.localeCompare(b.label))
      })
    },
    setModel(val) {
      this.title = val
      this.$emit('setEditLabelTitle', this.title)
    }
  },
  watch: {
    editLabelTitle: function(val) {
      this.title = val
    }
  }
}
</script>
