import { render, staticRenderFns } from "./EditInfo.vue?vue&type=template&id=65e2fad9&"
import script from "./EditInfo.vue?vue&type=script&lang=js&"
export * from "./EditInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTr,QTd,QIcon,QBtn,QRadio,QInput,QTooltip,QPopupProxy,QDate});qInstall(component, 'directives', {ClosePopup});
