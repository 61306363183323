<template>
  <fragment>
    <q-tr
      :props="props"
      class="edit-information head"
      @click="closeEdit(props.row.dataId)"
    >
      <q-td key="dataLabel" :props="props" class="title">
        <span :class="props.row.status"></span>
        <template v-if="!editLabel">
          <span>{{ props.row.dataLabel }}</span>
          <q-icon
            class="table-edit-icon"
            @click.stop="editLabel = !editLabel"
          ></q-icon>
        </template>
        <template v-else>
          <form @submit.prevent="saveLabel()" @click.stop="">
            <Autocomplete
              :edit-label-title="editLabelTitle"
              @setEditLabelTitle="setEditLabelTitle"
            />
            <q-btn
              type="submit"
              class="btn-save"
              :disable="!editLabelTitle.length"
            >
              <q-icon class="save-icon" />
            </q-btn>
          </form>
        </template>
      </q-td>
      <q-td key="dataValue" :props="props">
        <span>{{ text | reformatIsMoney(props.row) }} </span>
      </q-td>
      <q-td key="sourceDocumentType" :props="props"> </q-td>
      <q-td key="sourceDocumentDate" :props="props"> </q-td>
    </q-tr>

    <q-tr
      class="edit-information variant"
      v-for="item in props.row.variants"
      :key="item.dataId"
      @click="e => updateEditInformation(e, item)"
    >
      <q-td key="dataLabel" class="edit-variant"></q-td>

      <q-td key="dataValue" v-if="!item.isEdit">
        <span :class="['status', item.status]"></span>
        <q-radio
          v-model="radio"
          @input="e => updateEditInformation(e, item)"
          :val="item.dataId"
          :label="
            !item.isEdit
              ? $options.filters.reformatIsMoney(item.dataValue, props.row)
              : null
          "
        />
      </q-td>

      <q-td key="dataValue" v-else class="edit-variant">
        <span :class="['status', item.status]"></span>
        <q-radio
          v-model="radio"
          @input="e => updateEditInformation(e, item)"
          :val="item.dataId"
          :label="!item.isEdit ? item.dataValue : null"
        />
        <q-input
          v-if="item.isEdit"
          v-model="editVariant.dataValue"
          @click.stop=""
          filled
          lazy-rules
          :rules="[val => (val && val.length > 0) || 'Obligatoire']"
          @keydown.enter.exact.prevent="e => saveVariant(e, editVariant)"
        />
      </q-td>

      <q-td key="sourceDocumentType" v-if="!item.isEdit">
        {{ item.sourceDocumentType }}
      </q-td>

      <q-td key="sourceDocumentType" v-else class="edit-variant">
        <q-input
          v-model="editVariant.sourceDocumentType"
          @click.stop=""
          filled
          lazy-rules
          :rules="[val => (val && val.length > 0) || 'Obligatoire']"
          @keydown.enter.exact.prevent="e => saveVariant(e, editVariant)"
        />
      </q-td>

      <q-td key="sourceDocumentDate" v-if="!item.isEdit">
        <div class="date">
          {{ item.sourceDocumentDate }}
        </div>
        <div class="actions">
          <q-icon
            class="table-eye-icon"
            v-if="
              item.sourceDocumentId &&
              item.sourceDocumentId != '0'
            "
            @click="e => openPDF(e, item.dataId, item.sourceDocumentId)"
          ></q-icon>
          <q-icon
            class="table-edit-icon"
            @click="e => edit(e, item.dataId)"
          ></q-icon>
          <q-icon class="table-copy-icon" @click="e => copy(e, item.dataValue)">
            <q-tooltip content-class="status">
              Copy information
            </q-tooltip>
          </q-icon>
          <q-icon
            class="table-trash-icon"
            @click="e => remove(e, item.dataId)"
          ></q-icon>
        </div>
      </q-td>

      <q-td key="sourceDocumentDate" v-else class="edit-variant" @click.stop="">
        <q-input
          filled
          v-model="editVariant.sourceDocumentDate"
          @click.stop=""
          mask="##/##/####"
          placeholder="Date"
          lazy-rules
          :rules="[val => (val && val.length > 0) || 'Obligatoire']"
          @keydown.enter.exact.prevent="e => saveVariant(e, editVariant)"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="editVariant.sourceDocumentDate"
                  mask="DD/MM/YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-btn
          class="btn-save"
          :disable="isEmptyItem"
          @click="e => saveVariant(e, editVariant)"
        >
          <q-icon class="save-icon" />
        </q-btn>
      </q-td>
    </q-tr>
    <DeleteModal
      :content.sync="contentDeleteVariant"
      :is-show.sync="isDelete"
      @closeModal="closeDeleteModal"
      @removed="removed"
    />
  </fragment>
</template>

<script>
import DeleteModal from '@/components/modals/Delete.vue'
import { mapGetters } from 'vuex'
import Autocomplete from '@/components/project/Autocomplete'

export default {
  name: 'EditInfo',
  components: {
    Autocomplete,
    DeleteModal
  },
  props: {
    entityId: {
      type: String,
      default: ''
    },
    props: {
      type: Object,
      default: () => {}
    },
    isDeleted: {
      type: Boolean,
      default: false
    },
    isEditDone: {
      type: Boolean,
      default: true
    },
    editItem: {
      type: Object,
      default: () => {}
    },
    editLabelDone: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      radio: Object.keys(this.editItem).length
        ? this.editItem.dataId
        : this.props.row.dataId,
      contentDeleteVariant: {
        title: "Supprimer l'information",
        text: [
          'Êtes-vous sûr de vouloir supprimer cette information ? ',
          'Vous ne pourrez pas annuler cette action.'
        ],
        id: null,
        removeUrl: ''
      },
      isDelete: false,
      text: this.props.row.dataValue,
      isEmptyItem: false,
      isEditItem: !!Object.keys(this.editItem).length,
      editVariant: this.editItem,
      editLabel: false,
      editLabelTitle: this.props.row.dataLabel
    }
  },
  computed: {
    ...mapGetters('projects', ['getErrorPutEntityItem'])
  },
  mounted() {
    this.$nextTick(function() {
      const el = document.querySelector('.edit-information.variant')
      if (el) {
        el.scrollIntoView({ block: 'center', behavior: 'auto' })
      }
    })
  },
  methods: {
    save(noClose = false) {
      if (typeof noClose !== 'boolean') noClose = false
      this.$emit('edit', this.radio, false, false, noClose)
    },
    saveVariant(e, item) {
      const { dataValue, sourceDocumentType, sourceDocumentDate } = item

      if (!dataValue || !sourceDocumentType || !sourceDocumentDate) return

      e.stopPropagation()
      if (this.radio === item.dataId) {
        this.text = item.dataValue
      }
      item.status = 'MANUAL'
      this.$emit('editVariant', item.dataId, false)
    },
    edit(e, id) {
      e.stopPropagation()
      this.$emit('editVariant', id, true)
    },
    remove(e, id) {
      e.stopPropagation()
      this.contentDeleteVariant.id = id
      this.isDelete = true
    },
    openPDF(e, dataId, documentId) {
      e.stopPropagation()
      this.$emit('openPDF', dataId, documentId)
    },
    closeDeleteModal() {
      this.isDelete = false
    },
    removed(id) {
      this.$emit('removedVariant', id)
    },
    copy(e, text) {
      e.stopPropagation()
      const input = document.createElement('textarea')
      document.body.appendChild(input)
      input.value = text
      input.focus()
      input.select()
      document.execCommand('copy')
      input.remove()
    },
    async updateEditInformation(e, item) {
      this.text = item.dataValue
      this.radio = item.dataId
      item.status = 'MANUAL'
      await this.$emit('editVariant', item.dataId, true)
      this.save(true)
    },
    hide() {
      this.isEditItem = false
      this.props.row.variants.forEach(variant => (variant.isEdit = false))
    },
    closeEdit(id) {
      this.$emit('closeEdit', id)
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    setEditLabelTitle(title) {
      this.editLabelTitle = title
    },
    saveLabel() {
      if (!this.editLabelTitle.length) return

      this.$emit('saveLabel', this.editVariant.dataId, this.editLabelTitle)
    }
  },
  filters: {
    reformatIsMoney(value, row) {
      var is_money = row.isMoney

      if (is_money == true) {
        const regexp = '[\\d-\ ,.()]' //eslint-disable-line
        const result = [...value.matchAll(regexp)]
        if (result.length != value.length) {
          return value
        }

        // Case 123.123,12
        var count = (value.match(/[,.]/g) || []).length
        if (count > 1) {
          if (value.indexOf('.') < value.indexOf(',')) {
            value = value.replace('.', '')
          } else {
            return value
          }
        }

        // Case 123.456
        var count_dot = (value.match(/[.]/g) || []).length
        var count_comma = (value.match(/[,]/g) || []).length
        if ((count_comma == 0) & (count_dot >= 1)) {
          var splited_value = value.split('.')
          if (splited_value[splited_value.length - 1].length == 3) {
            value = value.replace('.', '')
          }
        }

        var value_int = parseFloat(value.replace(/\s/g, '').replace(',', '.'))
        if (isNaN(value_int)) {
          return value
        } else {
          value = value_int.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR'
          })
          if (value[0] == '-') {
            value = '- ' + value.slice(1)
          }

          // Case 123 456,00 € to 123 456 €
          var testNoCents = value.substring(value.length - 5, value.length - 2)
          if (testNoCents.localeCompare(',00') == 0) {
            value = value.slice(0, value.length - 5) + ' €'
          }

          return value
        }
      }

      return value
    }
  },
  watch: {
    isDeleted: function(value) {
      if (value) this.isDelete = false
    },
    getErrorPutEntityItem: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    editItem: function(val) {
      const { isEdit } = val

      if (!isEdit) {
        this.isEditItem = false
        return
      }

      this.editVariant = val
    },
    editVariant: {
      handler(val) {
        const { dataValue, sourceDocumentType, sourceDocumentDate } = val

        if (val && Object.keys(val).length) {
          this.isEditItem = true

          this.isEmptyItem = !(
            sourceDocumentType.trim().length &&
            dataValue.trim().length &&
            sourceDocumentDate?.trim().length
          )
        } else {
          this.isEditItem = false
        }
      },
      deep: true
    },
    editLabelDone: function(status) {
      if (status) {
        this.editLabel = false
        this.$emit('resetEditLabelDone')
      }
    }
  }
}
</script>
